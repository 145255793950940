import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "main",
    component: () => import("@/views/main/index.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/main/navigationView/Home.vue"),
      },
      {
        path: "replay",
        name: "replay",
        component: () => import("@/views/main/navigationView/Replay.vue"),
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("@/views/main/navigationView/Setting.vue"),
      },
      {
        path: "thesedays",
        name: "thesedays",
        component: () => import("@/views/main/app/TheseDays.vue"),
      },
      {
        path: "message",
        name: "message",
        component: () => import("@/views/main/app/Message.vue"),
      },
      {
        path: "call",
        name: "call",
        component: () => import("@/views/main/app/Call.vue"),
      },
      {
        path: "contact",
        name: "contact",
        component: () => import("@/views/main/app/Contact.vue"),
      },
      {
        path: "camera",
        name: "camera",
        component: () => import("@/views/main/app/Camera.vue"),
      },
      {
        path: "google",
        name: "google",
        component: () => import("@/views/main/app/Google.vue"),
      },
      {
        path: "playstore",
        name: "playstore",
        component: () => import("@/views/main/app/PlayStore.vue"),
      },
      {
        path: "kakaotalk",
        name: "kakaotalk",
        component: () => import("@/views/main/app/Kakaotalk.vue"),
      },
      {
        path: "navermap",
        name: "navermap",
        component: () => import("@/views/main/app/NaverMap.vue"),
      },
      {
        path: "youtube",
        name: "youtube",
        component: () => import("@/views/main/app/Youtube.vue"),
      },
    ],
  },
  {
    path: "/voice-select",
    name: "voice-select",
    component: () => import("@/views/landing/VoiceSelect.vue"),
  },
  {
    path: "/end",
    name: "EndView",
    component: () => import("@/views/guide/end.vue"),
  },
  {
    path: "/thesedays",
    name: "TheseDaysGuideList",
    component: () => import("@/views/guide/theseDays/Index.vue"),
    children: [
      {
        path: "search_ways",
        name: "TheseDaysSearchWays",
        component: () => import("@/views/guide/theseDays/SearchWays/Index.vue"),
      },
      {
        path: "inquire",
        name: "TheseDaysInquire",
        component: () => import("@/views/guide/theseDays/Inquire/Index.vue"),
      },
      {
        path: "change_narration",
        name: "TheseDaysChangeNarration",
        component: () =>
          import("@/views/guide/theseDays/ChangeNarration/Index.vue"),
      },
      {
        path: "setting_time",
        name: "TheseDaysSettingTime",
        component: () =>
          import("@/views/guide/theseDays/SettingTIme/Index.vue"),
      },
      {
        path: "replay",
        name: "TheseDaysReplay",
        component: () => import("@/views/guide/theseDays/Replay/Index.vue"),
      },
    ],
  },
  {
    path: "/message",
    name: "MessageGuideList",
    component: () => import("@/views/guide/message/Index.vue"),
    children: [
      {
        path: "add_spam_messages",
        name: "AddSpamMessages",
        component: () =>
          import("@/views/guide/message/AddSpamMessages/Index.vue"),
      },
    ],
  },
  {
    path: "/camera",
    name: "CameraGuideList",
    component: () => import("@/views/guide/camera/Index.vue"),
    children: [
      {
        path: "timer_settings",
        name: "TimerSettings",
        component: () => import("@/views/guide/camera/TimerSettings/Index.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (
    (JSON.parse(localStorage.getItem("vuex")!) == null ||
      JSON.parse(localStorage.getItem("vuex")!).setting!.voice == null) &&
    to.name != "voice-select"
  ) {
    next("/voice-select");
  }
  console.log(to);
  if (to.matched.length == 0) {
    alert(
      "서비스를 지원하지 않는 페이지입니다. 빠른 시일 내에 서비스를 제공하겠습니다."
    );
    next(from);
  } else {
  }
  next();
});

export default router;
